@import '../../../../../styles/colors';

.performance {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  &__header-wrapper {
    position: relative; }

  &__header {
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 12px 36px 12px 22px;
    box-shadow: 0px 1px 20px rgba(0,0,0,.1);
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    background-color: $white;
    min-height: 100px;

    &--active {
      box-shadow: none;
      border: 1px solid $purple; } }

  &__image {
    width: 75px;
    height: 75px;
    background-color: $pale-blue;
    margin-right: 28px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: cover;
    flex-shrink: 0; }

  &__header-body {
    width: 100%; }

  &__header-plus {
    flex-shrink: 0;
    margin-left: 30px;
    width: 50px;
    height: 50px;
    background-color: $purple;
    border-radius: 50%;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &--inactive {
      background-color: $very-light-blue; } }

  &__time {
    letter-spacing: 1px;
    margin-bottom: 20px; }

  &__status {
    font-size: 12px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    margin-left: 20px; }

  &__status-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 15px;
    flex-shrink: 0;

    &--yellow {
      background-color: $maize; }

    &--green {
      background-color: $soft-green; }

    &--red {
      background-color: $red; } }

  &__name {
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 500; }

  &__header-buttons {
    display: flex;
    margin-left: 20px; }

  &__header-button {
    background-color: $white;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, .1);
    width: 54px;
    height: 54px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 15px; }

    &--active {
      background-color: $purple; }

    &--small {
      width: 35px;
      height: 35px;

      svg {
        width: 15px;
        height: 15px; } } }

  &__controls {
    display: flex;
    align-items: center;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translate(100%, -50%); }

  &__control-button {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px; }

    &--disabled {
      opacity: 0.5;
      cursor: default; } }

  &__info {
    padding: 30px 25px 30px 30px;
    width: 100%;
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    top: -100px;
    padding-top: 130px;
    box-shadow: 0px 1px 20px rgba(0,0,0,.1);
    border-radius: 50px 50px 35px 35px;
    background-color: $white;
    align-self: flex-end;
    margin-bottom: -100px;
    min-height: 404px; }

  &__info-inputs {
    position: relative;
    z-index: 2; }

  &__input {
    width: 110px;
    flex-shrink: 0;
    position: relative;

    .performance__slide-label {
      display: block; }

    &:nth-child(2) {
      margin-right: 15px; }

    &--date {
      margin-right: 15px; } }

  &__slide-label {
    display: none;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translate(-50%,100%);
    z-index: 2; }

  &__input-title {
    margin-right: 15px;
    display: flex;
    align-items: center; }

  &__input-separator {
    width: 20px;
    text-align: center; }

  &__performance-inputs-row {
    margin-top: 17px; }

  &__performance-eye {
    margin-top: 20px; }

  &__timeline {
    margin-top: 32px;
    position: relative;
    z-index: 1;
    pointer-events: none; }

  &__buttons {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }

  &__button {
    margin: 0 15px;

    &--save {
      margin-top: 11px; } }

  .button.button--purple.button--disabled {
    background-color: $purple;
    opacity: 0.5; }

  &__timeline-title {
    margin-top: 30px;
    color: $purple; }

  &__timeline-items {
    margin-top: 30px; }

  &__timeline-item {
    display: flex;
    margin-top: 23px; }

  &__timeline-time {
    width: 130px;
    letter-spacing: 1px;
    margin-right: 10px; }

  &__timeline-place {
    letter-spacing: 1px; } }
