@import "../../styles/colors.sass";

.button {
  width: 300px;
  max-width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all .2s ease-out;
  box-sizing: border-box;

  &--purple {
    background-color: $purple;
    color: $white;

    &:hover {
      color: $purple;
      background-color: rgba(147, 62, 197, 0.5); }

    &:active {
      background-color: $white;
      border: 1px solid $purple; } }

  &--transparent {
    border: 1px solid $white;
    color: $white;
    background-color: transparent; }

  &--pale-blue {
    background-color: $very-light-blue;
    color: $white;

    &.button--disabled {
      opacity: 0.5; } }

  &--black {
    background-color: $black;
    color: $white; }

  &--disabled {
    background-color: $very-light-blue;
    cursor: default;

    &:hover,&:active {
      color: $white;
      background-color: $very-light-blue;
      border: none; } }

  &--loading {
    background-color: $white;
    border: 1px solid $purple;
    cursor: default;

    &:hover {
      background-color: $white;
      border: 1px solid $purple; } }

  &__icon {
    flex-shrink: 0;
    margin-right: 15px;
    line-height: 0; }

  &__loader {
    svg {
      animation: buttonLoading 3s linear infinite; } }

  &--text-centered {
    text-align: center; } }


@keyframes buttonLoading {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
